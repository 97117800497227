import React from 'react'

export function Editor({ form, children }) {
  React.useEffect(() => {
    window.addEventListener('beforeunload', (e) => {
      if (form.dirty) {
        e.preventDefault()
        e.returnValue = ''
      }
    })
  })
  return (
    <>
      {form.values.isDraft && <div className="text-center mt-4">ENTWURF</div>}
      {children}
    </>
  )
}
