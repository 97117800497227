export const refreshSourcedContent = async () => {
  await fetch('/__refresh', { method: 'POST' })
}

export const refreshAndRedirectHome = async (timeout) => {
  await fetch('/__refresh', { method: 'POST' })
  setTimeout(() => {
    window.location.href = '../'
  }, timeout)
}

export const getDeleteRefreshTimeout = () => {
  return +process.env.REFRESH_TIMEOUT_MILLIS_DELETE
}

export const refreshAndRedirect = async (redirectUrl, timeout) => {
  await fetch('/__refresh', { method: 'POST' })
  setTimeout(() => {
    window.location.href = redirectUrl
  }, timeout)
}
