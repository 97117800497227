import React from 'react'
import { CMSWrapper } from '../components/cmsWrapper'
import { Navigation } from '../common/navigation'
import { Footer } from '../common/footer'
import { GlobalHelmet } from '../common/seo/globalHelmet'
import { graphql, Link } from 'gatsby'
import { StaticReferenceModules } from '../components/references/staticReferenceModules'
import { StaticImage } from '../components/staticSections'
import { useCMS, useForm, usePlugin } from 'tinacms'
import { Editor } from '../components/editor'
import fetch from 'node-fetch'
import { refreshSourcedContent } from '../cms/refresh'
import { InlineForm, InlineGroup } from 'react-tinacms-inline'
import { Button } from '../common/button'
import { Icon } from '../common/icon'

export default function Cluster({ data, pageContext }) {
  let initialDataLoader

  const loadCluster = async () => {
    const clusterResponse = await fetch(`/_cms/clusters/${pageContext.id}`)
    return clusterResponse.json()
  }

  if (process.env.NODE_ENV === 'production') {
    initialDataLoader = {
      initialValues: data.cluster,
    }
  }

  if (process.env.NODE_ENV !== 'production') {
    initialDataLoader = {
      async loadInitialValues() {
        return loadCluster().then((cluster) => cluster)
      },
    }
  }

  return (
    <ClusterTemplate
      data={data}
      pageContext={pageContext}
      initialDataLoader={initialDataLoader}
    />
  )
}

function ClusterTemplate({ initialDataLoader, data, pageContext }) {
  const stationReferences = data.allReference.nodes
  const clusterStations = pageContext.clusterStations

  const ClusterForm = {
    title: 'Cluster',
    fields: [
      {
        label: 'Short List anzeigen',
        name: 'showShortList',
        component: 'toggle',
      },
      {
        label: 'Long List anzeigen',
        name: 'showLongList',
        component: 'toggle',
      },
      {
        name: 'landingpageShowInExplorer',
        label: 'In der „Stöbern“-Übersicht anzeigen',
        description:
          'Ein-/Ausblenden einer Clusterlandingpage in der „Stöbern“-Übersicht',
        component: 'toggle',
      },
    ],
    onSubmit(formData) {
      cms.alerts.info('Änderungen werden gespeichert...')

      return fetch(`/_cms/clusters/${formData._id}`, {
        method: 'PUT',
        body: JSON.stringify({
          ...formData,
        }),
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
      })
        .then((response) => {
          if (response.ok) {
            cms.alerts.success('Speichern erfolgreich.')
          } else if (response.status === 401 || response.status === 403) {
            cms.alerts.error(
              'Sie haben nicht die Berechtigung, den Datensatz zu speichern!',
              5000
            )
          } else {
            response.json().then((data) => {
              console.log(
                `Updating cluster with id ${formData._id} failed: ` +
                  JSON.stringify(data)
              )
              cms.alerts.error(
                `Speichern fehlgeschlagen: ${JSON.stringify(data)}`,
                8000
              )
            })
          }
          return response.ok
        })
        .then(async (ok) => {
          if (ok === true) {
            await refreshSourcedContent()
          }
        })
        .catch((e) => {
          cms.alerts.error('Fehler beim Speichern!')
          console.log('Updating cluster failed: ' + e)
        })
    },
    ...initialDataLoader,
  }

  const cms = useCMS()
  const [editableData, form] = useForm(ClusterForm)
  usePlugin(form)

  return (
    <CMSWrapper>
      <GlobalHelmet title={`UKE jukebox - ${editableData.title}`} />
      <main>
        <Navigation />
        <Editor form={form}>
          <InlineForm form={form}>
            <header className="bg-img-yellow divider-left">
              <div className="row align-items-center">
                {editableData.key && editableData.key === 'geburtshilfe' ? (
                  <>
                    <div className="col-md-6 offset-md-3 col-10 offset-1 text-center">
                      <img
                        src="/img/handlettering/handlettering-happy-birthday-every-day.svg"
                        alt="Happy birthday every day"
                        width="400"
                      />
                    </div>
                  </>
                ) : editableData.key &&
                  editableData.key === 'herz-and-gefassmedizin' ? (
                  <>
                    <div className="col-md-5 offset-md-1 col-10 offset-1 text-center">
                      <img
                        src="/img/handlettering/handlettering-alles-was-das-herz-begehrt.svg"
                        alt="Handlettering: Alles was das Herz begehrt"
                        width="400"
                      />
                    </div>
                    <div className="col-md-5 offset-md-0 col-10 offset-1 text-center">
                      <div className="image-container image-container--frame image-container--static">
                        <img
                          src="/img/herz-und-gefaessmedizin/HerzJobs_Karriere-im-UHZ-UHZ__13_slider.jpg"
                          alt="Herz- und Gefäßmedizin Kopf"
                        />
                      </div>
                    </div>
                  </>
                ) : editableData.key &&
                  editableData.key === 'innere-medizin' ? (
                  <>
                    <div className="col-md-6 offset-md-3 col-10 offset-1 text-center">
                      <img
                        src="/img/handlettering/handlettering-bei-uns-geht-es-um-die-inneren-werte.svg"
                        alt="Happy birthday every day"
                        width="400"
                      />
                    </div>
                  </>
                ) : editableData.key &&
                  editableData.key === 'intensivmedizin' ? (
                  <>
                    <div className="col-md-5 offset-md-1 col-10 offset-1 text-center">
                      <img
                        src="/img/handlettering/handlettering-es-geht-um-Leben-und-TOD.svg"
                        alt="Es geht um Leben und Tod"
                        width="400"
                      />
                    </div>

                    <div className="col-md-5 offset-md-0 col-10 offset-1 text-center">
                      <figure className="m-0">
                        <div className="image-container image-container--frame image-container--static">
                          <img
                            src="/img/intensivpflege/intensivkampagne-kopf.jpg"
                            alt="Intensivkampagne Titelbild"
                            loading="lazy"
                          />
                        </div>
                      </figure>
                    </div>

                    <div className="col-md-10 col-10 offset-1 text-center mt-4">
                      <h1 className="mt-4">
                        <strong>
                          Deine Wahl: 12 unterschiedlichste Intensivstationen im
                          UKE
                        </strong>
                      </h1>
                      <h3>
                        Es gibt nichts Intensiveres als die Intensiv. All day
                        long und durch die Nacht kümmern wir uns um die
                        Menschen, deren Leben auf der Kippe steht...
                      </h3>
                      <h2>
                        <strong>Ist das was für dich?</strong>
                      </h2>
                    </div>
                  </>
                ) : (
                  <div className="col-md-6 offset-md-3 col-10 offset-1 text-center">
                    <img
                      src={`/img/handlettering/${editableData.handlettering}`}
                      alt="Cluster Handlettering"
                      width="400"
                    />
                  </div>
                )}
              </div>
            </header>

            {editableData.key && editableData.key === 'intensivmedizin' && (
              <>
                <section id="matching-stats">
                  <div
                    id="stats"
                    className="row align-items-center text-center"
                  >
                    <div className="col-md-3 col-12">
                      <h3>Die UKE Intensivmedizin in Zahlen</h3>
                    </div>
                    <div className="col-md-3 col-4">
                      <figure>
                        <Icon name="team" margin="mb-2"></Icon>
                        <figcaption>
                          <h4>
                            Teams <span>13</span>
                          </h4>
                        </figcaption>
                      </figure>
                    </div>
                    <div className="col-md-3 col-4">
                      <figure>
                        <figcaption>
                          <h4>
                            <span>1</span>&nbsp; Mission{' '}
                          </h4>
                        </figcaption>
                      </figure>
                    </div>
                    <div className="col-md-3 col-4">
                      <figure>
                        <Icon name="bed" margin="mb-2"></Icon>
                        <figcaption>
                          <h4>
                            Bettenanzahl <span>140</span>
                          </h4>
                        </figcaption>
                      </figure>
                    </div>
                  </div>
                </section>

                <section className="bg-blue divider-left mt-5">
                  <div className="row align-items-center">
                    <div className="col-md-10 offset-md-1 col-12 mt-3">
                      <h2>
                        Moin und willkommen in der Intensivpflege im UKE. Schaue
                        hier, was die Arbeit bei uns so besonders macht:
                      </h2>
                      <figure className="video-container video-container--static">
                        <iframe
                          src="https://player.vimeo.com/video/669845574?h=faa6104ce6"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          frameBorder="0"
                          webkitallowfullscreen="true"
                          mozallowfullscreen="true"
                          allowFullScreen
                          loading="lazy"
                        ></iframe>
                      </figure>
                    </div>
                  </div>
                </section>

                <section className="bg-blue divider-right mt-5">
                  <div className="row align-items-center">
                    <div className="col-md-10 offset-md-1 col-12">
                      <h2>Werde auch du Intensivpflege-Profi im UKE!</h2>
                    </div>
                  </div>

                  <div className="row align-items-center">
                    <div className="col-md-5 offset-md-1 col-12 text-start">
                      <figure className="m-0">
                        <div className="image-container image-container--frame image-container--static">
                          <img
                            src="/img/intensivpflege/477095.jpg"
                            alt="Intensivstationen des UKE"
                            loading="lazy"
                          />
                        </div>
                      </figure>
                      <figcaption className="text-center">
                        Bei uns hat das Team eine tragende Rolle
                      </figcaption>
                    </div>

                    <div className="col-md-5 col-12 text-start">
                      <p>
                        Du hast Lust auf einen abwechslungsreichen Arbeitstag,
                        möchtest dich weiterentwickeln, über den Tellerrand
                        hinausschauen oder einfach ohne Papierakten arbeiten?
                        Dann bist du bei uns als Gesundheits- und
                        Krankenpfleger/in richtig!
                        <br />
                        Denn wir wachsen weiter und suchen Verstärkung. Egal ob
                        Berufsanfänger, Wiedereinsteiger, Berufserfahrene (aus
                        Intensiv und Anästhesie) - dabei spielt es keine Rolle,
                        ob mit oder ohne Fachweiterbildung. Wir haben die
                        passenden Stellenangebote für dich. Werde auch du Teil
                        der Intensivpflege im UKE.
                      </p>
                    </div>

                    <div className="col-md-10 offset-md-1 col-12 mt-4">
                      <ul>
                        <li>
                          Zeit für die Patienten - kein leeres Versprechen
                        </li>
                        <li>Einarbeitung so individuell wie du selbst</li>
                        <li>
                          <a
                            href="https://www.uke.de/organisationsstruktur/zentrale-bereiche/uke-akademie-fuer-bildung-karriere/fort-weiterbildung/index.html"
                            target="new"
                          >
                            Für alle die mehr wollen - Weiterbildungen
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </section>

                <section className="bg-blue divider-left mt-5">
                  <div className="row align-items-center">
                    <div className="col-md-8 offset-md-2 col-12">
                      <h2>Patient im Mittelpunkt - kein leeres Versprechen!</h2>
                    </div>
                  </div>

                  <div className="row align-items-center">
                    <div className="col-md-8 offset-md-2 col-12 text-center">
                      <figure className="m-0">
                        <div className="image-container image-container--frame image-container--static">
                          <img
                            src="/img/intensivpflege/477100.jpg"
                            alt="Intensivstationen des UKE"
                            loading="lazy"
                          />
                        </div>
                        <figcaption className="text-center">
                          Auch wenn es manchmal rau ist - wir sind mit Herz bei
                          der Sache
                        </figcaption>
                      </figure>
                    </div>

                    <div className="col-md-8 offset-md-2 col-12 text-start mt-4">
                      <h3>DAS UKE SCHAFFT DAFÜR DIE VORAUSSETZUNG</h3>
                      <ul>
                        <li>Kein Medikamentenstellen - zentrale Apotheke</li>
                        <li>Kein Papierkram - elektronischen Patientenakten</li>
                        <li>Servicekräfte für das Patientenessen</li>
                        <li>Servicekräfte für die Bettplatzreinigung</li>
                        <li>
                          Materialanlieferung direkt an den Patientenplatz
                        </li>
                        <li>Der Müll wird direkt auf Station abgeholt</li>
                        <li>Transporte von Patienten nur im Notfall</li>
                      </ul>
                    </div>
                  </div>
                </section>

                <section className="bg-blue divider-right">
                  <div className="row align-items-center">
                    <div className="col-md-10 offset-md-1 col-12">
                      <h2>
                        Deine Einarbeitung:
                        <br /> So individuell wie du{' '}
                      </h2>
                    </div>
                  </div>

                  <div className="col-md-8 offset-md-2 col-12 text-center mt-3">
                    <figure className="m-0">
                      <div className="image-container image-container--frame image-container--static">
                        <img
                          src="/img/intensivpflege/477099.jpg"
                          alt="Intensivstationen des UKE"
                          loading="lazy"
                        />
                      </div>
                      <figcaption className="text-center">
                        Wir passen aufeinander auf.
                      </figcaption>
                    </figure>
                  </div>

                  <div className="row align-items-center mt-4">
                    <div className="col-md-10 offset-md-1 col-12 text-start">
                      <h4 className="text-uppercase">UKE-Einführungstage</h4>
                      <p>
                        Bei uns startest du zunächst mit übergeordneten
                        Einführungstagen. Zwei Tage lang lernst du
                        berufsübergreifend das UKE kennen. Danach verbringst du
                        drei weitere gemeinsame Tage mit allen Pflegenden im
                        UKE, die im gleichen Monat angefangen haben wie du. Hier
                        erhältst du breitgefächerte Informationen rund um das
                        UKE und die Entwicklungsmöglichkeiten sowie Schulungen,
                        Pflichtfortbildungen rund um deinen neuen
                        Tätigkeitsbereich.
                      </p>
                      <br />
                      <h4 className="text-uppercase">Einarbeitung vor Ort</h4>
                      <p>
                        Danach fängt die fachspezifische Einarbeitung bei uns im
                        Intensivbereich an. Du erhältst von Anfang an feste
                        Mentoren sowie Praxisanleiter, die dich bei der
                        praktischen und theoretischen Einweisung eng begleiten.
                        Außerdem definieren wir direkt zu Beginn gemeinsam deine
                        "Heimatstation“ - die Station, in der du später
                        eingesetzt wirst. Wir arbeiten dich dabei individuell
                        ein - je nach fachlichem Kenntnisstand. Dabei ist es uns
                        wichtig, regelmäßige Feedbackgespräche mit dir zu
                        führen, um den Start für dich so optimal wie möglich zu
                        gestalten.
                      </p>
                      <br />
                      <h4 className="text-uppercase">
                        GUT IN HAMBURG ANKOMMEN
                      </h4>
                      <p>
                        Falls du möchtest, haben deine neuen Kolleginnen und
                        Kollegen auch Tipps für Trips in Hamburg für dich. Auch
                        das gehört bei uns zur Einarbeitung dazu.
                        <br />
                      </p>
                      <br />
                      <h4 className="text-uppercase">URLAUBSREIF</h4>
                      <p>
                        Wir wissen, dass gerade die Anfangsphase für dich sehr
                        aufregend ist und du viele neue Eindrücke sammelst.
                        Damit die Erholung nicht zu kurz kommt, darfst du bei
                        uns auch während der Probezeit anteilig Urlaub nehmen.
                        <br />
                      </p>
                      <br />
                      <h4 className="text-uppercase">
                        WIR UNTERSTÜTZEN DICH BEIM UMZUG
                      </h4>
                      <p>
                        Wir möchten dich bestmöglich beim Start in unserer
                        schönsten Stadt der Welt unterstützen. Sollte für deine
                        Tätigkeit in unserem Intensivbereich ein Umzug nach
                        Hamburg nötig werden, übernehmen wir für dich die
                        entstandenen Kosten bis zu einer Höhe von 1.000 Euro.
                        Voraussetzung dafür ist, dass ein Umzug von außerhalb
                        des HVV-Großbereiches notwendig wird. Sprich uns gerne
                        beim Vorstellungsgespräch auf eine finanzielle
                        Umzugsunterstützung an.
                        <br />
                      </p>
                      <br />
                      <h2>
                        <strong>
                          Uns ist es wichtig, dass du einen guten Start bei uns
                          hast!
                        </strong>
                      </h2>
                      <br />
                    </div>
                  </div>
                </section>

                <section className="bg-blue divider-left">
                  <div className="row align-items-center">
                    <div className="col-md-10 offset-md-1 col-12">
                      <h2>Für alle, die mehr wollen </h2>
                    </div>

                    <div className="col-md-8 offset-md-2 col-12 text-center mt-3">
                      <figure className="m-0">
                        <div className="image-container image-container--frame image-container--static">
                          <img
                            src="/img/intensivpflege/477097.jpg"
                            alt="Intensivstationen des UKE"
                            loading="lazy"
                          />
                        </div>
                        <figcaption className="text-center">
                          Wir passen aufeinander auf.
                        </figcaption>
                      </figure>
                    </div>
                  </div>

                  <div className="row align-items-center mt-4">
                    <div className="col-md-10 offset-md-1 col-12 text-start">
                      <h4 className="text-uppercase">
                        Unsere Weiterbildungsmöglichkeiten
                      </h4>
                      <p>
                        Du möchtest mehr als nur einen neuen Job? Weiterbildung
                        und deine Entwicklung sind dir wichtig? Das passt gut!
                        Wir bieten für die Intensivpflege umfangreiche Aus- und
                        Weiterbildungsmaßnahmen an. Beginnend mit wöchentlichen
                        Stationsfortbildungen und Notfall-Trainings bis hin zur
                        Akademisierung in der Pflege. Dabei sind bei uns auch
                        Weiterbildungen in Teilzeit möglich.
                        <br />
                        <br />
                        Wir garantieren dir die Fachweiterbildung für
                        Intensivpflege bereits innerhalb des ersten
                        Beschäftigungsjahres - und das ohne Warteliste.
                        <br />
                        <br />
                        Wenn du über den Tellerrand deiner "Heimatstation“
                        schauen möchtest, bieten wir dir vielfältige
                        Rotationsmöglichkeiten, die anderen elf
                        Intensivstationen und damit verbundenen Fachbereiche
                        kennenzulernen.
                        <br />
                        <br />
                        Wenn du als Gesundheits- und Krankenpfleger/in bereits
                        eine Fachweiterbildung gemacht hast und bei einem
                        Wechsel Rückzahlungsforderungen bestehen, übernehmen wir
                        diese ohne wenn und aber!
                      </p>
                      <a
                        href="https://www.uke.jobs/index.php?ac=search_result&search_criterion_activity_level%5B%5D=3"
                        title="Weiterleitung zu den Fortbildungsangeboten vom UKE"
                      >
                        Finde deine passenden Stellenangebote und informiere
                        dich hier (auf uke.de)
                      </a>
                    </div>
                  </div>
                </section>
              </>
            )}

            {editableData.key &&
              editableData.key === 'kinder-and-jugendmedizin' && (
                <>
                  <section className="bg-blue divider-right">
                    <div className="row align-items-center">
                      <div className="col-md-10 offset-md-1 col-12">
                        <h2>
                          <b>Willkommen im Kinder-UKE</b>
                        </h2>
                        <h3>
                          <strong>
                            Wir arbeiten in der modernsten Kinderklinik des
                            Nordens: Im Kinder-UKE.
                          </strong>
                        </h3>
                        <p>
                          Ende September 2017 sind wir im Kinder-UKE eingezogen.
                          Es war ein großartiger Moment. Nicht nur für unsere
                          kleinen Patient:innen, sondern auch für unsere
                          Mitarbeitenden. Unser Neubau schafft optimale
                          Rahmenbedingen für eine zukunftsfähige, kindgerechte
                          Spitzenmedizin.
                          <br />
                          Modernste medizinische Technik ist gepaart mit
                          liebevoll gestalteten Räumen, die konsequent auf die
                          Bedürfnisse der jungen Patient:innen und ihrer
                          Angehörigen ausgerichtet sind. Noch stärker vernetzte
                          Stationen, kurze Wege und einander ergänzende
                          Therapiemöglichkeiten – diese Punkte machen unser
                          neues Kinder-UKE aus. All das macht die Arbeit für uns
                          besonders.
                          <br />
                          Schau dir hier unseren Film zum Kinder-UKE an und
                          erfahre, was es für unsere kleinen Patient:innen und
                          Mitarbeitenden zu bieten hat:
                        </p>
                      </div>
                    </div>

                    <div className="row align-items-center">
                      <div className="col-md-10 offset-md-1 col-12">
                        <figure className="video-container video-container--static">
                          <iframe
                            src="https://player.vimeo.com/video/402591817"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            frameBorder="0"
                            webkitallowfullscreen="true"
                            mozallowfullscreen="true"
                            allowFullScreen
                            loading="lazy"
                          ></iframe>
                        </figure>
                        <h5>Arbeiten im Kinder-UKE</h5>
                        <h5>
                          <strong>
                            Nach knapp über einem Jahr der Eröffnung unseres
                            Kinder-UKE haben wir unsere Kolleg:innen befragt,
                            was das Arbeiten hier so besonders macht.
                          </strong>
                        </h5>
                      </div>
                    </div>

                    <div className="row align-items-center pt-5">
                      <div className="col-md-10 offset-md-1 col-12">
                        <h2> Wir suchen dich!</h2>
                        <p>
                          Mit dem Neubau ist das Kinder-UKE gewachsen. Insgesamt
                          werden wir die Anzahl unserer Gesundheits- und
                          Kinderkrankenpfleger:innen um ca. 40 Prozent erhöhen,
                          unsere medizinischen Fachangestellten verdoppeln und
                          die Arbeitsplätze im Bereich der OP- und
                          Anästhesiepflege nahezu vervierfachen.
                          <br />
                          <b>
                            Daher haben wir unterschiedlichste Stellenangebote
                            und suchen dich als neue:n Kolleg:in in der
                            Krankenpflege für unsere 17 Bereiche & Stationen.
                          </b>
                          <br />
                          <br />
                        </p>
                      </div>
                    </div>
                  </section>

                  <section className="bg-blue divider-right mt-5">
                    <div className="row align-items-center">
                      <div className="col-md-10 offset-md-1 col-12 text-start">
                        <h2>
                          <b>
                            Warum du bei uns im Kinder-UKE arbeiten solltest
                          </b>
                        </h2>
                        <h3>
                          <b>
                            Es gibt sicherlich viele Gründe, warum du deine
                            Karriere bei uns im Kinder-UKE machen solltest. Wir
                            möchten dir ein paar davon nennen:
                          </b>
                        </h3>
                      </div>
                    </div>

                    <div className="row align-items-center">
                      <div className="col-md-10 offset-md-1 col-12 text-start mt-4">
                        <h3>
                          <b>Keine Suche nach Patient:innenakten</b>
                        </h3>
                        <p>
                          Im UKE arbeiten wir bereits seit einigen Jahren mit
                          der elektronischen Patient:innenakte und nutzen diese
                          ebenfalls im neuen Kinder-UKE. Auf diese Weise haben
                          alle Gesundheitsfachberufe die Möglichkeit
                          Ortsunabhängig auf diese zuzugreifen.
                        </p>
                        <br />
                        <h3>
                          <b>Was dich im Kinder-UKE erwartet</b>
                        </h3>
                        <p>
                          Unser Kinderkrankenhaus besticht neben einer modernen
                          Gestaltung auch mit einer optimalen Lage in Hamburg -
                          direkt gegenüber vom Eppendorfer Park. Für unsere
                          Mitarbeitenden bieten wir die modernste Technik, kurze
                          Wege und Möglichkeiten, die Pausen relaxt zu
                          verbringen, z.B. in unserem Health Kitchen Bistro.
                          Übrigens: Erreicht werden kann das neue Kinder-UKE
                          ganz einfach mit dem Bus, dem StadtRad oder den
                          Carsharing-Angeboten.
                        </p>
                        <br />
                        <h3>
                          <b>Wir übernehmen deine Umzugskosten</b>
                        </h3>
                        <p>
                          Du hast einen Job im Kinder-UKE bekommen? Dann möchten
                          wir dich bestmöglich beim Start in unserer schönsten
                          Stadt der Welt unterstützen. Sollte ein Umzug nach
                          Hamburg nötig werden, übernehmen wir für dich die
                          entstandenen Kosten bis zu einer Höhe von 1.000 Euro.
                          Voraussetzung dafür ist, dass ein Umzug von außerhalb
                          des HVV-Großbereiches notwendig wird. Sprich uns gerne
                          beim Vorstellungsgespräch auf eine finanzielle
                          Umzugsunterstützung an.
                        </p>
                      </div>
                      <div className="col-md-10 offset-md-1 col-12 text-start mt-4">
                        <h3>
                          <b>... was du noch erwarten kannst:</b>
                        </h3>
                        <ul>
                          <li>
                            Dienstplangestaltung
                            <ul>
                              <li>
                                Du arbeitest maximal sieben Tage hintereinander
                              </li>
                              <li>Du arbeitest maximal vier Nächte am Stück</li>
                              <li>
                                Wir berücksichtigen nach Möglichkeit deine
                                Wünsche bei der Dienstplangestaltung
                              </li>
                            </ul>
                          </li>
                          <li>
                            Teilnahme an Arbeitsgruppen zur Weiterentwicklung
                            der Pflege und/oder Expertenstandards
                          </li>
                          <li>
                            Qualifikation innerhalb des Mentorensystems
                            (Hygienementor:in, pädiatrische:r Schmerzmentor:in,
                            Mentor:in für neue
                            Mitarbeiter/Auszubildende/Praktikanten)
                          </li>
                          <li>
                            Qualifikation zur:m Ethikberater:in,
                            Praxisanleiter:in, Urotherapeut:in,
                            Ambulanzmanager:in
                          </li>
                          <li>
                            Teilnahme an Kongressen und an fachspezifischen
                            Fortbildungen (Triagesystem in der Notaufnahme,
                            Transplantationen, Wundversorgung etc.)
                          </li>
                          <li>
                            Teilnahme an Fortbildungen unser UKE Akademie für
                            Bildung und Karriere
                          </li>
                          <li>
                            Freiwillige Mitarbeit bei unserem
                            Kooperationspartner „Kinderlotse e.V“
                          </li>
                          <li>
                            Möglichkeit der Teilnahme an der pädiatrischen
                            Anästhesie- und Intensivfachweiterbildung oder der
                            onkologischen Fachweiterbildung
                          </li>
                          <li>
                            Option zur Qualifikation im Bereich Pflegemanagement
                          </li>
                          <li>
                            Option auf einen Stipendiumplatz zur
                            Master-Qualifikation im Bereich Pflege an der
                            Hochschule für Angewandte Wissenschaft
                          </li>
                        </ul>
                      </div>
                    </div>
                  </section>

                  <section className="bg-blue divider-left mt-5">
                    <div className="row align-items-center">
                      <div className="col-md-10 offset-md-1 col-12 text-start">
                        <h2>
                          <b>
                            Unser Einarbeitungskonzept - auf dich zugeschnitten
                          </b>
                        </h2>
                        <p>
                          Bei uns startest du immer mit übergeordneten
                          UKE-Einführungstagen. Zwei Tage lang lernst du das UKE
                          berufsübergreifend kennen. Danach verbringst du drei
                          weitere gemeinsame Tage mit allen Pflegenden im UKE,
                          die im gleichen Monat mit dir anfangen. Hier erhältst
                          du breit&shy;gefächerte Infor&shy;mationen rund um die
                          Uniklinik und die Entwicklungsmöglichkeiten sowie
                          Schulungen, Pflicht&shy;fort&shy;bildungen rund um
                          deinen neuen Tätigkeits&shy;bereich.
                          <br />
                          Danach fängt die fachspezifische Einarbeitung in
                          deinem neuen Job direkt im Bereich an. Feste
                          Ansprechpartner:innen, Schulungstage, regelmäßiges
                          Feedback, inhaltliche Einarbeitungskonzepte pro
                          Bereich und langsame Steigerung bei der
                          selbstständigen Übernahme der Patient:innenversorgung
                          sind unsere Stärken.
                          <br />
                          Und falls du möchtest, haben deine Kolleg:innen auch
                          Tipps für Unternehmungen in Hamburg. Auch das gehört
                          bei uns zur Einarbeitung dazu.
                          <br />
                          Wir wissen, dass gerade die Anfangsphase für dich sehr
                          aufregend ist und du viele neue Eindrücke sammelst.
                          Damit die Erholung nicht zu kurz kommt, darfst du bei
                          uns auch während der Probezeit anteilig Urlaub nehmen.
                          <br />
                          Uns ist es wichtig, dass du einen guten Start bei uns
                          hast!
                        </p>
                      </div>

                      <div className="col-md-10 offset-md-1 col-12">
                        <div id="superslide-1" className="carousel slide w-100">
                          <div className="carousel-inner">
                            <div className="carousel-item active">
                              <img
                                src="/img/kinder-und-jugendmedizin/Kinder_UKE_Baum_20150731_3.jpg"
                                alt="Bildansichten vom Kinder-UKE"
                                loading="lazy"
                              />
                            </div>

                            <div className="carousel-item">
                              <img
                                src="/img/kinder-und-jugendmedizin/Kinder_UKE_Aussen_20170602_106FAV.jpg"
                                alt="Bildansichten vom Kinder-UKE"
                                loading="lazy"
                              />
                              <p>
                                <small>Die Fassade des neuen Kinder-UKE</small>
                              </p>
                            </div>

                            <div className="carousel-item">
                              <img
                                src="/img/kinder-und-jugendmedizin/Kinder_UKE_20170823_Innen-17.jpg"
                                alt="Bildansichten vom Kinder-UKE"
                                loading="lazy"
                              />
                              <p>
                                <small>
                                  Der Empfangsbereich des Kinder-UKE
                                </small>
                              </p>
                            </div>
                            <div className="carousel-item">
                              <img
                                src="/img/kinder-und-jugendmedizin/Kinder_UKE_20170823_Innen-21.jpg"
                                alt="Bildansichten vom Kinder-UKE"
                                loading="lazy"
                              />
                              <p>
                                <small>
                                  Der Eingangs- und Empfangsbereich im neuen
                                  Kinder-UKE
                                </small>
                              </p>
                            </div>
                            <div className="carousel-item">
                              <img
                                src="/img/kinder-und-jugendmedizin/Kinder_UKE_20170815_1302.jpg"
                                alt="Bildansichten vom Kinder-UKE"
                                loading="lazy"
                              />
                              <p>
                                <small>
                                  Beispiel eines neuen Spielzimmer mit
                                  Bauelementen im Stil des Hamburger Hafens.
                                </small>
                              </p>
                            </div>
                            <div className="carousel-item">
                              <img
                                src="/img/kinder-und-jugendmedizin/Kinder_UKE_20170316_116FAV.jpg"
                                alt="Bildansichten vom Kinder-UKE"
                                loading="lazy"
                              />
                              <p>
                                <small>
                                  Beispiel eines neuen Patientenzimmers
                                </small>
                              </p>
                            </div>
                            <div className="carousel-item">
                              <img
                                src="/img/kinder-und-jugendmedizin/Kinder_UKE_Aussen_20170602_165.jpg"
                                alt="Bildansichten vom Kinder-UKE"
                                loading="lazy"
                              />
                              <p>
                                <small>
                                  Eingang zur Hämatologisch-Onkologischen
                                  Ambulanz
                                </small>
                              </p>
                            </div>
                            <div className="carousel-item">
                              <img
                                src="/img/kinder-und-jugendmedizin/Neubau_Kinder_UKE_20170215_12FAV.jpg"
                                alt="Bildansichten vom Kinder-UKE"
                                loading="lazy"
                              />
                              <p>
                                <small>
                                  Hainbuche und Glasfront im großen Innenhof
                                </small>
                              </p>
                            </div>
                          </div>

                          <div className="carousel-nav">
                            <button
                              className="carousel-control-prev"
                              data-bs-target="#superslide-1"
                              role="button"
                              data-bs-slide="prev"
                            >
                              <span
                                className="carousel-control-prev-icon"
                                aria-hidden="true"
                              ></span>
                              <span className="visually-hidden">Previous</span>
                            </button>
                            <button
                              className="carousel-control-next"
                              data-bs-target="#superslide-1"
                              role="button"
                              data-bs-slide="next"
                            >
                              <span
                                className="carousel-control-next-icon"
                                aria-hidden="true"
                              ></span>
                              <span className="visually-hidden">Next</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className="bg-blue divider-right mt-5">
                    <div className="row align-items-center">
                      <div className="col-md-10 offset-md-1 col-12 text-start">
                        <h2>
                          <b>Du hast Lust auf einen Job im Kinder-UKE?</b>
                        </h2>
                        <p>
                          Dann haben wir für dich die passenden Stellenangebote
                          auf einer unserer 17 Stationen. Du kannst dich ganz
                          einfach über die Stationsseiten bewerben oder eine
                          Hospitationsanfrage stellen. Auch bei Fragen stehen
                          dir die Stationsleitungen jederzeit zur Verfügung.
                          Mach den nächsten Schritt und schau, welcher Bereich
                          am besten zu dir passt. Wir freuen uns auf dich.
                        </p>
                      </div>
                      <div className="col-md-10 offset-md-1 col-12 text-start mt-4">
                        <h2>
                          <b>Das Kinder-UKE</b>
                        </h2>
                        <p>
                          Als UKE bieten wir dir vielfältige Arbeitsumfelder der
                          Pädiatrie, in denen du dich bei uns einbringen und
                          verwirklichen kannst.
                        </p>
                        <p>
                          Das Kinder-UKE bietet Kindern bis 18 Jahre eine
                          Maximalversorgung. Die klinischen Schwerpunkte
                          umfassen Krebserkrankungen, schwere neurologische und
                          seltene angeborene Erkrankungen. Patient:innen
                          berichten in diesem Film wie sich Spitzenmedizin und
                          Fürsorge im Kinder-UKE vereinen.
                        </p>
                      </div>
                    </div>

                    <div className="row align-items-center">
                      <div className="col-md-10 offset-md-1 col-12">
                        <figure className="video-container video-container--static">
                          <iframe
                            src="https://player.vimeo.com/video/403210251"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            frameBorder="0"
                            webkitallowfullscreen="true"
                            mozallowfullscreen="true"
                            allowFullScreen
                            loading="lazy"
                          ></iframe>
                        </figure>
                      </div>
                    </div>
                  </section>

                  <section className="bg-blue divider-left">
                    <div className="row align-items-center">
                      <div className="col-md-10 offset-md-1 col-12">
                        <h2>
                          <b>Der Bau des Kinder-UKE</b>
                        </h2>
                        <p>
                          Das Kinder-UKE wurde im September 2017 eröffnet und
                          bezogen.
                          <br />
                          Schau dir hier Bilder unseres Gebäudes an:
                        </p>
                        <div id="superslide-2" className="carousel slide w-100">
                          <div className="carousel-inner">
                            <div className="carousel-item active">
                              <img
                                src="/img/kinder-und-jugendmedizin/Kinder_UKE_Baum_20150731_3.jpg"
                                alt="Bildansichten vom Kinder-UKE"
                                loading="lazy"
                              />
                              <p>
                                <small>
                                  Außenaufnahme des Eingangsbereichs
                                </small>
                              </p>
                            </div>

                            <div className="carousel-item">
                              <img
                                src="/img/kinder-und-jugendmedizin/Kinder_UKE_Aussen_20170602_106FAV.jpg"
                                alt="Bildansichten vom Kinder-UKE"
                                loading="lazy"
                              />
                              <p>
                                <small>Die Fassade des neuen Kinder-UKE</small>
                              </p>
                            </div>

                            <div className="carousel-item">
                              <img
                                src="/img/kinder-und-jugendmedizin/Kinder_UKE_20170823_Innen-17.jpg"
                                alt="Bildansichten vom Kinder-UKE"
                                loading="lazy"
                              />
                              <p>
                                <small>
                                  Der Empfangsbereich des Kinder-UKE
                                </small>
                              </p>
                            </div>
                            <div className="carousel-item">
                              <img
                                src="/img/kinder-und-jugendmedizin/Kinder_UKE_20170823_Innen-21.jpg"
                                alt="Bildansichten vom Kinder-UKE"
                                loading="lazy"
                              />
                              <p>
                                <small>
                                  Der Eingangs- und Empfangsbereich im neuen
                                  Kinder-UKE
                                </small>
                              </p>
                            </div>
                            <div className="carousel-item">
                              <img
                                src="/img/kinder-und-jugendmedizin/Kinder_UKE_20170815_1302.jpg"
                                alt="Bildansichten vom Kinder-UKE"
                                loading="lazy"
                              />
                              <p>
                                <small>
                                  Beispiel eines neuen Spielzimmer mit
                                  Bauelementen im Stil des Hamburger Hafens.
                                </small>
                              </p>
                            </div>
                            <div className="carousel-item">
                              <img
                                src="/img/kinder-und-jugendmedizin/Kinder_UKE_20170316_116FAV.jpg"
                                alt="Bildansichten vom Kinder-UKE"
                                loading="lazy"
                              />
                              <p>
                                <small>
                                  Beispiel eines neuen Patientenzimmers
                                </small>
                              </p>
                            </div>
                            <div className="carousel-item">
                              <img
                                src="/img/kinder-und-jugendmedizin/Neubau_Kinder_UKE_20170215_12FAV.jpg"
                                alt="Bildansichten vom Kinder-UKE"
                                loading="lazy"
                              />
                              <p>
                                <small>
                                  Kleiner Innenhof des neuen Kinder-UKE
                                </small>
                              </p>
                            </div>
                            <div className="carousel-item">
                              <img
                                src="/img/kinder-und-jugendmedizin/Kinder_UKE_Aussen_20170602_165.jpg"
                                alt="Bildansichten vom Kinder-UKE"
                                loading="lazy"
                              />
                              <p>
                                <small>
                                  Eingang zur Hämatologisch-Onkologischen
                                  Ambulanz
                                </small>
                              </p>
                            </div>
                            <div className="carousel-item">
                              <img
                                src="/img/kinder-und-jugendmedizin/Neubau_Kinder_UKE_20170215_12FAV.jpg"
                                alt="Bildansichten vom Kinder-UKE"
                                loading="lazy"
                              />
                              <p>
                                <small>
                                  Hainbuche und Glasfront im großen Innenhof
                                </small>
                              </p>
                            </div>
                          </div>

                          <div className="carousel-nav">
                            <button
                              className="carousel-control-prev"
                              data-bs-target="#superslide-2"
                              role="button"
                              data-bs-slide="prev"
                            >
                              <span
                                className="carousel-control-prev-icon"
                                aria-hidden="true"
                              ></span>
                              <span className="visually-hidden">Previous</span>
                            </button>
                            <button
                              className="carousel-control-next"
                              data-bs-target="#superslide-2"
                              role="button"
                              data-bs-slide="next"
                            >
                              <span
                                className="carousel-control-next-icon"
                                aria-hidden="true"
                              ></span>
                              <span className="visually-hidden">Next</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row align-items-center mt-5">
                      <div className="col-md-10 offset-md-1 col-12 mt-5">
                        <p>
                          Bereits im Juni 2014 begann der Bau unseres Kinder-UKE
                          und startete zu dem Zeitpunkt mit dem Rückbau alter
                          Gebäude. Die Fläche des Gebäudes wurde dann für den
                          eigentlichen Bau vorbereitet. Vor Baubeginn stand nur
                          noch die Hainbuche, die sich später im Innenhof des
                          neuen Kinder-UKE befindet.
                        </p>
                        <p>
                          Schauen Sie sich hier unseren Film zum Neubau des
                          Kinder-UKE an, den wir bereits in 2015 zum Baubeginn
                          erstellt haben und bei dem die Wünsche unserer jungen
                          Patient:innen berücksichtigt wurden.
                        </p>
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <div className="col-md-10 offset-md-1 col-12">
                        <figure className="video-container video-container--static">
                          <iframe
                            src="https://player.vimeo.com/video/403281795"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            frameBorder="0"
                            webkitallowfullscreen="true"
                            mozallowfullscreen="true"
                            allowFullScreen
                            loading="lazy"
                          ></iframe>
                        </figure>
                      </div>
                    </div>
                  </section>
                </>
              )}

            {editableData.key && editableData.key === 'herz-and-gefassmedizin' && (
              <>
                <section id="matching-stats">
                  <div
                    id="stats"
                    className="row align-items-center text-center"
                  >
                    <div className="col-md-3 col-12">
                      <h3>Die UKE Herz- und Gefäßmedizin in Zahlen</h3>
                    </div>
                    <div className="col-md-3 col-12">
                      <figure>
                        <Icon name="team" margin="mb-2"></Icon>
                        <figcaption>
                          <h4>
                            Patient:innen <span>~30.000</span>
                          </h4>
                        </figcaption>
                      </figure>
                    </div>
                    <div className="col-md-3 col-12">
                      <figure>
                        <Icon name="team-3" margin="mb-2"></Icon>
                        <figcaption>
                          <h4>
                            Pflegefachpersonen <span>~300</span>
                          </h4>
                        </figcaption>
                      </figure>
                    </div>
                    <div className="col-md-3 col-12">
                      <figure>
                        <Icon name="calendar" margin="mb-2"></Icon>
                        <figcaption>
                          <h4>
                            Gepl. Neubau-Fertigstellung <span>2025</span>
                          </h4>
                        </figcaption>
                      </figure>
                    </div>
                  </div>
                </section>

                <section className="bg-blue divider-right">
                  <div className="row align-items-center">
                    <div className="col-md-10 offset-md-1 col-12">
                      <h2>
                        <b>
                          Willkommen im Universitären Herz- und Gefäßzentrum
                          (UHZ)
                        </b>
                      </h2>
                    </div>
                    <div className="col-md-5 col-10 offset-1">
                      <div className="image-container image-container--frame image-container--static">
                        <img
                          src="/img/herz-und-gefaessmedizin/HerzJobs_Karriere-im-UHZ-UHZ__08_slider.jpg"
                          alt="Herz- und Gefäßmedizin"
                        />
                      </div>
                    </div>
                    <div className="col-md-5 offset-md-0 col-10 offset-1">
                      <p>
                        Bei uns dreht sich alles um das Herz und die Gefäße...
                        Vom Kunstherz bis zur Herztransplantation – Wir sind mit
                        vollem Einsatz dabei, wenn es darum geht, unsere
                        Patientinnen und Patienten bestmöglich bei ihrer
                        Genesung zu unterstützen. Hierbei bieten wir dir einen
                        verantwortungsvollen und sicheren Arbeitsplatz mit
                        Zukunftsperspektive – mitten im Herzen von Hamburg.
                      </p>
                    </div>
                    <div className="col-10 offset-1">
                      <p>
                        Wir bieten auf einem hohen wissenschaftlichen Niveau
                        Diagnostiken und Therapien für sämtliche
                        Herz-/Kreislauf- und Gefäßerkrankungen (inkl.
                        Transplantation) an. Auf 10 Stationen, 6
                        Katheterlaboren, 7 OP-Sälen und einer großen Herz- und
                        Gefäßambulanz werden unsere Patientinnen und Patienten
                        aller Altersklassen interdisziplinär betreut und
                        behandelt.
                      </p>
                    </div>
                  </div>
                </section>

                <section className="bg-blue divider-left">
                  <div className="row align-items-center">
                    <div className="col-10 offset-1">
                      <h2>
                        <strong>Warum du zu uns kommen solltest</strong>
                      </h2>
                    </div>

                    <div className="col-md-5 offset-md-1 col-10 offset-1">
                      <p>
                        <strong>Wir leben Vielfalt</strong>
                      </p>
                      <p>
                        Die Stationen sind klein und die jungen Teams bunt
                        gemischt. Du übernimmst vielfältige und
                        verantwortungsvolle Aufgaben - nah an unseren
                        Patientinnen und Patienten und nah an moderner Pflege
                        und Medizin.{' '}
                      </p>
                      <p>
                        Außerdem gilt bei uns ein ganz wichtiger Faktor:{' '}
                        <strong>Wir lassen dich nicht allein!</strong>{' '}
                        Nachtdienste werden auf unseren Monitorstationen immer
                        mindestens zu zweit absolviert und das Team hat für
                        deine Anliegen stets ein offenes Ohr.
                      </p>
                    </div>

                    <div className="col-md-5 offset-md-0 col-10 offset-1">
                      <div id="superslide-3" className="carousel slide w-100">
                        <div className="carousel-inner">
                          <div className="carousel-item active">
                            <div className="image-container image-container--frame image-container--static">
                              <img
                                src="/img/herz-und-gefaessmedizin/HerzJobs_Karriere-im-UHZ-UHZ__11_slider.jpg"
                                alt="Eindrücke vom Universitären Herz- und Gefäßzentrum (UHZ)"
                                loading="lazy"
                              />
                            </div>
                          </div>

                          <div className="carousel-item">
                            <div className="image-container image-container--frame image-container--static">
                              <img
                                src="/img/herz-und-gefaessmedizin/HerzJobs_Karriere-im-UHZ-UHZ__07_slider.jpg"
                                alt="Eindrücke vom Universitären Herz- und Gefäßzentrum (UHZ)"
                                loading="lazy"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="carousel-nav">
                          <button
                            className="carousel-control-prev"
                            data-bs-target="#superslide-3"
                            role="button"
                            data-bs-slide="prev"
                          >
                            <span
                              className="carousel-control-prev-icon"
                              aria-hidden="true"
                            ></span>
                            <span className="visually-hidden">Previous</span>
                          </button>
                          <button
                            className="carousel-control-next"
                            data-bs-target="#superslide-3"
                            role="button"
                            data-bs-slide="next"
                          >
                            <span
                              className="carousel-control-next-icon"
                              aria-hidden="true"
                            ></span>
                            <span className="visually-hidden">Next</span>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-5 offset-md-1 col-10 offset-1">
                      <div id="superslide-4" className="carousel slide w-100">
                        <div className="carousel-inner">
                          <div className="carousel-item active">
                            <div className="image-container image-container--frame image-container--static">
                              <img
                                src="/img/herz-und-gefaessmedizin/HerzJobs_Karriere-im-UHZ-UHZ__04.jpg"
                                alt="Eindrücke vom Universitären Herz- und Gefäßzentrum (UHZ)"
                                loading="lazy"
                              />
                            </div>
                          </div>

                          <div className="carousel-item">
                            <div className="image-container image-container--frame image-container--static">
                              <img
                                src="/img/herz-und-gefaessmedizin/HerzJobs_Karriere-im-UHZ-UHZ__03.jpg"
                                alt="Eindrücke vom Universitären Herz- und Gefäßzentrum (UHZ)"
                                loading="lazy"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="carousel-nav">
                          <button
                            className="carousel-control-prev"
                            data-bs-target="#superslide-4"
                            role="button"
                            data-bs-slide="prev"
                          >
                            <span
                              className="carousel-control-prev-icon"
                              aria-hidden="true"
                            ></span>
                            <span className="visually-hidden">Previous</span>
                          </button>
                          <button
                            className="carousel-control-next"
                            data-bs-target="#superslide-4"
                            role="button"
                            data-bs-slide="next"
                          >
                            <span
                              className="carousel-control-next-icon"
                              aria-hidden="true"
                            ></span>
                            <span className="visually-hidden">Next</span>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-5 offset-md-0 col-10 offset-1">
                      <p>
                        <strong>Keine „patientenfernen“ Tätigkeiten</strong>
                      </p>
                      <p>
                        Die Pflege unserer Herz- und Gefäßpatienten steht bei
                        uns an erste Stelle. Damit du dich voll und ganz darauf
                        konzentrieren kannst, entlasten wir dich bei den
                        „patientenfernen“ Tätigkeiten:
                      </p>
                      <p>
                        Um die die gastronomische Versorgung der Patientinnen
                        und Patienten kümmert sich ein spezielles Serviceteam
                        und das Stellen von Medikamenten (zentrale Prüfung,
                        Beschriftung & Verpackung) wird durch das
                        UKE-Unit-Dose-System der Hausapotheke übernommen.
                      </p>
                    </div>

                    <div className="col-md-5 offset-md-1 col-10 offset-1">
                      <p>
                        <strong>Keine Suche nach Patientenakten</strong>
                      </p>
                      <p>
                        Im UKE arbeiten wir bereits seit einigen Jahren mit der
                        elektronischen Patientenakte und nutzen diese auch im
                        UHZ. So müssen unsere Krankenschwestern und
                        Krankenpfleger nicht ständig nach Papierakten suchen,
                        wie es in vielen anderen Häusern noch der Fall ist. Wir
                        nutzen diese Zeit für das Wesentliche: Die Pflege
                        unserer Patientinnen und Patienten.
                      </p>
                    </div>

                    <div className="col-md-5 offset-md-0 col-10 offset-1">
                      <div id="superslide-5" className="carousel slide w-100">
                        <div className="carousel-inner">
                          <div className="carousel-item active">
                            <div className="image-container image-container--frame image-container--static">
                              <img
                                src="/img/herz-und-gefaessmedizin/HerzJobs_Karriere-im-UHZ-UHZ__10_slider.jpg"
                                alt="Eindrücke vom Universitären Herz- und Gefäßzentrum (UHZ)"
                                loading="lazy"
                              />
                            </div>
                          </div>

                          <div className="carousel-item">
                            <div className="image-container image-container--frame image-container--static">
                              <img
                                src="/img/herz-und-gefaessmedizin/HerzJobs_Karriere-im-UHZ-UHZ__05.jpg"
                                alt="Eindrücke vom Universitären Herz- und Gefäßzentrum (UHZ)"
                                loading="lazy"
                              />
                            </div>
                          </div>

                          <div className="carousel-item">
                            <div className="image-container image-container--frame image-container--static">
                              <img
                                src="/img/herz-und-gefaessmedizin/HerzJobs_Karriere-im-UHZ-UHZ__06.jpg"
                                alt="Eindrücke vom Universitären Herz- und Gefäßzentrum (UHZ)"
                                loading="lazy"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="carousel-nav">
                          <button
                            className="carousel-control-prev"
                            data-bs-target="#superslide-5"
                            role="button"
                            data-bs-slide="prev"
                          >
                            <span
                              className="carousel-control-prev-icon"
                              aria-hidden="true"
                            ></span>
                            <span className="visually-hidden">Previous</span>
                          </button>
                          <button
                            className="carousel-control-next"
                            data-bs-target="#superslide-5"
                            role="button"
                            data-bs-slide="next"
                          >
                            <span
                              className="carousel-control-next-icon"
                              aria-hidden="true"
                            ></span>
                            <span className="visually-hidden">Next</span>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-5 offset-md-1 col-10 offset-1">
                      <div id="superslide-6" className="carousel slide w-100">
                        <div className="carousel-inner">
                          <div className="carousel-item active">
                            <div className="image-container image-container--frame image-container--static">
                              <img
                                src="/img/herz-und-gefaessmedizin/HerzJobs_Karriere-im-UHZ-UHZ__09_slider.jpg"
                                alt="Eindrücke vom Universitären Herz- und Gefäßzentrum (UHZ)"
                                loading="lazy"
                              />
                            </div>
                          </div>

                          <div className="carousel-item">
                            <div className="image-container image-container--frame image-container--static">
                              <img
                                src="/img/herz-und-gefaessmedizin/HerzJobs_Karriere-im-UHZ-UHZ__02.jpg"
                                alt="Eindrücke vom Universitären Herz- und Gefäßzentrum (UHZ)"
                                loading="lazy"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="carousel-nav">
                          <button
                            className="carousel-control-prev"
                            data-bs-target="#superslide-6"
                            role="button"
                            data-bs-slide="prev"
                          >
                            <span
                              className="carousel-control-prev-icon"
                              aria-hidden="true"
                            ></span>
                            <span className="visually-hidden">Previous</span>
                          </button>
                          <button
                            className="carousel-control-next"
                            data-bs-target="#superslide-6"
                            role="button"
                            data-bs-slide="next"
                          >
                            <span
                              className="carousel-control-next-icon"
                              aria-hidden="true"
                            ></span>
                            <span className="visually-hidden">Next</span>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-5 offset-md-0 col-10 offset-1">
                      <p>
                        <strong>Super Einarbeitungskonzept</strong>
                      </p>
                      <p>
                        Bei uns startest du immer mit{' '}
                        <strong>übergeordneten UKE-Einführungstagen</strong>.
                        Danach fängt{' '}
                        <strong>die fachspezifische UHZ-Einarbeitung</strong> in
                        deinem neuen Bereich an. Feste Ansprechpartner/- innen,
                        Schulungstage, regelmäßiges Feedback, inhaltliche
                        Einarbeitungskonzepte pro Bereich und langsame
                        Steigerung bei der selbstständigen Übernahme der
                        Versorgung von Patienten und Patientinnen sind unsere
                        Stärken.
                      </p>
                    </div>
                  </div>
                </section>

                <section className="bg-blue divider-right">
                  <div className="row align-items-center">
                    <div className="col-md-10 offset-md-1 col-12">
                      <h2>
                        <b>... was du noch erwarten kannst:</b>
                      </h2>
                    </div>
                    <div className="col-md-10 offset-md-1 col-12">
                      <ul>
                        <li>
                          Mitarbeiterfreundliche Dienstplangestaltung:
                          <br />
                          - Die Dienstplanung erfolgt ca. 6 Wochen im Voraus
                          <br />
                          - Du arbeitest maximal sieben Tage hintereinander
                          <br />
                          - Du arbeitest maximal vier Nächte am Stück
                          <br />
                          - Wir berücksichtigen deine Wünsche bei der
                          Dienstplangestaltung (mind. 4 Wünsche im Monat)
                          <br />- Urlaub kann auch Anteilig in der Probezeit
                          genommen werden.
                        </li>
                        <li>
                          Talente können zudem in unserer{' '}
                          <strong>Potentialanalyse (POTA)</strong> unter Beweis
                          gestellt werden.
                        </li>
                        <li>
                          Das UKE vergibt jedes Jahr{' '}
                          <strong>
                            Stipendien für den Ausbau der wissenschaftlichen
                            Pflegeexpertise.
                          </strong>
                        </li>
                        <li>
                          <strong>
                            Kostenübernahme und zeitliche Freistellung
                          </strong>{' '}
                          für Fort- und Weiterbildungen
                        </li>
                        <li>
                          <strong>
                            Qualifikation innerhalb des Mentorensystems
                          </strong>{' '}
                          (Hygienementor/in, Schmerzmentor/in, Mentor/in für
                          neue Mitarbeitende/Auszubildende/Praktikant/innen,
                          Mentor/in für Menschen mit Demenz)
                        </li>
                        <li>
                          <strong>Weitere Qualifikationsoptionen</strong> bspw.
                          zur/m, Praxisanleiter/in, Wundexpertin/-experten
                        </li>
                        <li>
                          <strong>
                            Teilnahme an Kongressen und an fachspezifischen
                            internen Fortbildungen
                          </strong>{' '}
                          (Transplantationen, Medi-kamenten- und
                          Notfallmanagement, Wundversorgung etc.)
                        </li>
                        <li>
                          <strong>Teilnahme an Fortbildungen</strong> unser UKE
                          Akademie für Bildung und Karriere (ABK)
                        </li>
                        <li>
                          <strong>Beteiligung an Deinen Umzugskosten</strong>{' '}
                          bis zu 2.000 €{' '}
                        </li>
                        <li>
                          <strong>Nachhaltig unterwegs:</strong> Zuschüsse zum
                          HVV-ProfiTicket und Dr. Bike Fahrradservice; Option
                          zum Dienstrad-Leasing
                        </li>
                        <li>
                          Möglichkeit, im Rahmen unseres{' '}
                          <strong>
                            Mitarbeitendenprogramms (UKE INside) aktiv Ihre
                            Arbeitswelt mitzugestalten
                          </strong>{' '}
                          und weiterzuentwickeln
                        </li>
                        <li>
                          <strong>Familienfreundliches Arbeitsumfeld:</strong>{' '}
                          Kooperation zur Kinderbetreuung, kostenlose
                          Ferienbetreuung, Beratung für Beschäftigte mit
                          pflegebedürftigen Angehörigen
                        </li>
                        <li>
                          <strong>
                            Ausgezeichnete Gesundheits-, Präventions- und
                            Sportangebote
                          </strong>
                        </li>
                      </ul>
                    </div>
                  </div>
                </section>

                <section className="bg-blue divider-left">
                  <div className="row align-items-center">
                    <div className="col-md-10 offset-md-1 col-12 mt-4">
                      <h3>
                        <b>Geschichten mit Herz... </b>
                      </h3>
                      <p>
                        Bei uns kannst du selbst ein Teil solch toller
                        Geschichten werden. Erlebe, wie unsere Patientinnen und
                        Patienten eine neue Chance in ihrem Leben erhalten -
                        auch dank deiner Hilfe!
                      </p>
                      <figure className="video-container video-container--static">
                        <iframe
                          src="https://player.vimeo.com/video/422710522"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          frameBorder="0"
                          webkitallowfullscreen="true"
                          mozallowfullscreen="true"
                          allowFullScreen
                          loading="lazy"
                        ></iframe>
                      </figure>
                    </div>
                  </div>
                </section>

                <section className="bg-blue divider-left">
                  <div className="row align-items-center">
                    <div className="col-md-10 offset-md-1 col-12 mt-4">
                      <p>
                        <strong>
                          Im Universitären Herz- und Gefäßzentrum (UHZ) wird es
                          spannend:
                        </strong>{' '}
                        Die Fassade ist nahezu fertiggestellt und der
                        Innenausbau läuft auf Hochtouren. Die Eröffnung des
                        neuen Gebäudes O60 ist für 2025 geplant.
                      </p>
                      <figure className="video-container video-container--static">
                        <iframe
                          src="https://player.vimeo.com/video/729942491?h=aae3eb05b3"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          frameBorder="0"
                          webkitallowfullscreen="true"
                          mozallowfullscreen="true"
                          allowFullScreen
                          loading="lazy"
                        ></iframe>
                      </figure>

                      <div
                        id="superslide-50"
                        className="carousel slide w-100 my-4"
                      >
                        <div className="carousel-inner text-center">
                          <div className="carousel-item active">
                            <div className="image-container image-container--frame image-container--static">
                              <img
                                src="/img/herz-und-gefaessmedizin/UHZ-Neubau-12.webp"
                                alt="Eindrücke vom Universitären Herz- und Gefäßzentrum (UHZ)"
                                loading="lazy"
                              />
                            </div>
                          </div>

                          <div className="carousel-item">
                            <div className="image-container image-container--frame image-container--static">
                              <img
                                src="/img/herz-und-gefaessmedizin/UHZ-Neubau-01.jpg"
                                alt="Eindrücke vom Universitären Herz- und Gefäßzentrum (UHZ)"
                                loading="lazy"
                              />
                            </div>
                          </div>

                          <div className="carousel-item">
                            <div className="image-container image-container--frame image-container--static">
                              <img
                                src="/img/herz-und-gefaessmedizin/UHZ-Neubau-02.jpg"
                                alt="Eindrücke vom Universitären Herz- und Gefäßzentrum (UHZ)"
                                loading="lazy"
                              />
                            </div>
                          </div>

                          <div className="carousel-item">
                            <div className="image-container image-container--frame image-container--static">
                              <img
                                src="/img/herz-und-gefaessmedizin/UHZ-Neubau-03.jpg"
                                alt="Eindrücke vom Universitären Herz- und Gefäßzentrum (UHZ)"
                                loading="lazy"
                              />
                            </div>
                          </div>

                          <div className="carousel-item">
                            <div className="image-container image-container--frame image-container--static">
                              <img
                                src="/img/herz-und-gefaessmedizin/UHZ-Neubau-04.jpg"
                                alt="Eindrücke vom Universitären Herz- und Gefäßzentrum (UHZ)"
                                loading="lazy"
                              />
                            </div>
                          </div>

                          <div className="carousel-item">
                            <div className="image-container image-container--frame image-container--static">
                              <img
                                src="/img/herz-und-gefaessmedizin/UHZ-Neubau-05.jpg"
                                alt="Eindrücke vom Universitären Herz- und Gefäßzentrum (UHZ)"
                                loading="lazy"
                              />
                            </div>
                          </div>

                          <div className="carousel-item">
                            <div className="image-container image-container--frame image-container--static">
                              <img
                                src="/img/herz-und-gefaessmedizin/UHZ-Neubau-06.jpg"
                                alt="Eindrücke vom Universitären Herz- und Gefäßzentrum (UHZ)"
                                loading="lazy"
                              />
                            </div>
                          </div>

                          <div className="carousel-item">
                            <div className="image-container image-container--frame image-container--static">
                              <img
                                src="/img/herz-und-gefaessmedizin/UHZ-Neubau-07.jpg"
                                alt="Eindrücke vom Universitären Herz- und Gefäßzentrum (UHZ)"
                                loading="lazy"
                              />
                            </div>
                          </div>

                          <div className="carousel-item">
                            <div className="image-container image-container--frame image-container--static">
                              <img
                                src="/img/herz-und-gefaessmedizin/UHZ-Neubau-08.jpg"
                                alt="Eindrücke vom Universitären Herz- und Gefäßzentrum (UHZ)"
                                loading="lazy"
                              />
                            </div>
                          </div>

                          <div className="carousel-item">
                            <div className="image-container image-container--frame image-container--static">
                              <img
                                src="/img/herz-und-gefaessmedizin/UHZ-Neubau-09.jpg"
                                alt="Eindrücke vom Universitären Herz- und Gefäßzentrum (UHZ)"
                                loading="lazy"
                              />
                            </div>
                          </div>

                          <div className="carousel-item">
                            <div className="image-container image-container--frame image-container--static">
                              <img
                                src="/img/herz-und-gefaessmedizin/UHZ-Neubau-10.jpg"
                                alt="Eindrücke vom Universitären Herz- und Gefäßzentrum (UHZ)"
                                loading="lazy"
                              />
                            </div>
                          </div>

                          <div className="carousel-item">
                            <div className="image-container image-container--frame image-container--static">
                              <img
                                src="/img/herz-und-gefaessmedizin/UHZ-Neubau-11.jpg"
                                alt="Eindrücke vom Universitären Herz- und Gefäßzentrum (UHZ)"
                                loading="lazy"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="carousel-nav">
                          <button
                            className="carousel-control-prev"
                            data-bs-target="#superslide-50"
                            role="button"
                            data-bs-slide="prev"
                          >
                            <span
                              className="carousel-control-prev-icon"
                              aria-hidden="true"
                            ></span>
                            <span className="visually-hidden">Previous</span>
                          </button>
                          <button
                            className="carousel-control-next"
                            data-bs-target="#superslide-50"
                            role="button"
                            data-bs-slide="next"
                          >
                            <span
                              className="carousel-control-next-icon"
                              aria-hidden="true"
                            ></span>
                            <span className="visually-hidden">Next</span>
                          </button>
                        </div>
                      </div>

                      <p>
                        Durch den Neubau profitieren sowohl Patient:innen, als
                        auch unsere Kolleg:innen der unterschiedlichen
                        Disziplinen: Neben der Behandlung hochkomplexer und
                        seltener Herz- und Gefäßerkrankungen wird durch die
                        Integration des gesamten Kinderherzbereichs eine
                        ganzheitliche Betreuung von Herzkrankheiten und die
                        Grundlage neuer Synergien geschaffen.
                      </p>
                      <p>
                        <strong>Auch du kannst profitieren:</strong> Neue Teams,
                        moderne Stationen und vielfältige Krankheitsbilder – Sei
                        von der ersten Stunde mit dabei und starte mit uns im
                        Neubau durch.
                      </p>
                    </div>
                  </div>
                </section>
              </>
            )}

            {editableData.key && editableData.key === 'geburtshilfe' && (
              <section className="bg-blue divider-right">
                <div className="row align-items-center">
                  <div className="col-md-10 offset-md-1 col-12">
                    <h2>
                      <b>Willkommen im Kinder-UKE</b>
                    </h2>
                    <h3>
                      <strong>
                        Wir arbeiten in der modernsten Kinderklinik des Nordens:
                        Im Kinder-UKE.
                      </strong>
                    </h3>

                    <p>
                      Ende September 2017 sind wir im Kinder-UKE eingezogen. Es
                      war ein großartiger Moment. Nicht nur für unsere kleinen
                      Patient:innen, sondern auch für unsere Mitarbeitenden.
                      Unser Neubau schafft optimale Rahmenbedingen für eine
                      zukunftsfähige, kindgerechte Spitzenmedizin.
                    </p>
                    <p>
                      Modernste medizinische Technik ist gepaart mit liebevoll
                      gestalteten Räumen, die konsequent auf die Bedürfnisse der
                      jungen Patient:innen und ihrer Angehörigen ausgerichtet
                      sind. Noch stärker vernetzte Stationen, kurze Wege und
                      einander ergänzende Therapiemöglichkeiten – diese Punkte
                      machen unser neues Kinder-UKE aus. All das macht die
                      Arbeit für uns besonders.
                    </p>
                    <p>
                      Schau dir hier unseren Film zum Kinder-UKE an und erfahre,
                      was es für unsere kleinen Patient:innen und Mitarbeitenden
                      zu bieten hat:
                    </p>
                  </div>
                </div>

                <div className="row align-items-center">
                  <div className="col-md-10 offset-md-1 col-12">
                    <figure className="video-container video-container--static">
                      <iframe
                        src="https://player.vimeo.com/video/402591817"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        frameBorder="0"
                        webkitallowfullscreen="true"
                        mozallowfullscreen="true"
                        allowFullScreen
                        loading="lazy"
                      ></iframe>
                    </figure>

                    <h5>Arbeiten im Kinder-UKE</h5>
                    <h5>
                      <strong>
                        Nach knapp über einem Jahr der Eröffnung unseres
                        Kinder-UKE haben wir unsere Kolleginnen und Kollegen
                        befragt, was das Arbeiten hier so besonders macht.
                      </strong>
                    </h5>
                  </div>
                </div>

                <div className="row align-items-center pt-5">
                  <div className="col-md-10 offset-md-1 col-12">
                    <h2> Wir suchen dich!</h2>
                    <p>
                      Mit dem Neubau ist das Kinder-UKE gewachsen. Insgesamt
                      werden wir die Anzahl unserer Gesundheits- und
                      Kinderkrankenpfleger:innen um ca. 40 Prozent erhöhen,
                      unsere medizinischen Fachangestellten verdoppeln und die
                      Arbeitsplätze im Bereich der OP- und Anästhesiepflege
                      nahezu vervierfachen.{' '}
                      <strong>
                        Daher haben wir unterschiedlichste Stellenangebote und
                        suchen dich als neue:n Kolleg:innen in der Krankenpflege
                        für unsere 17 Bereiche & Stationen.
                      </strong>
                    </p>
                  </div>
                </div>
              </section>
            )}

            <InlineGroup
              name="showShortList"
              insetControls
              fields={[
                {
                  name: '',
                  label: 'Short List anzeigen?',
                  component: 'toggle',
                },
              ]}
            >
              {editableData.showShortList && (
                <section className="bg-blue divider-right">
                  <div className="col-md-10 offset-md-1 col-12">
                    <h2>{`STÖBERE DURCH DIE BEREICHE (Shortlist) - ${editableData.title}:`}</h2>
                    <ul>
                      <StationShortList stations={clusterStations} />
                    </ul>
                  </div>
                </section>
              )}
            </InlineGroup>

            <InlineGroup
              name="showLongList"
              insetControls
              fields={[
                {
                  name: '',
                  label: 'Long List anzeigen?',
                  component: 'toggle',
                },
              ]}
            >
              {editableData.showLongList && (
                <>
                  <section id="matching-stats">
                    <div className="row align-items-center pt-4">
                      <div className="col-md-10 offset-md-1 col-12">
                        <h2 className="m-0">LONGLIST:</h2>
                      </div>
                    </div>
                  </section>

                  <StationLongList stations={clusterStations} />
                </>
              )}
            </InlineGroup>

            {editableData.key && editableData.key === 'geburtshilfe' && (
              <>
                <section className="bg-blue divider-right mt-5">
                  <div className="row align-items-center">
                    <div className="col-md-10 offset-md-1 col-12 text-start">
                      <h2>
                        <b>Warum du bei uns im Kinder-UKE arbeiten solltest</b>
                      </h2>
                      <h3>
                        <b>
                          Es gibt sicherlich viele Gründe, warum du deine
                          Karriere bei uns im Kinder-UKE machen solltest. Wir
                          möchten dir ein paar davon nennen:
                        </b>
                      </h3>
                    </div>
                  </div>

                  <div className="row align-items-center">
                    <div className="col-md-10 offset-md-1 col-12 text-start mt-4">
                      <h3>
                        <b>Keine Suche nach Patient:innenakten</b>
                      </h3>
                      <p>
                        Im UKE arbeiten wir bereits seit einigen Jahren mit der
                        elektronischen Patient:innenakte und nutzen diese
                        ebenfalls im neuen Kinder-UKE. Auf diese Weise haben
                        alle Gesundheitsfachberufe die Möglichkeit
                        Ortsunabhängig auf diese zuzugreifen.
                      </p>
                      <br />
                      <h3>
                        <b>Was dich im neuen Kinder-UKE erwartet</b>
                      </h3>
                      <p>
                        Unser Kinderkrankenhaus besticht neben einer modernen
                        Gestaltung auch mit einer optimalen Lage in Hamburg -
                        direkt gegenüber vom Eppendorfer Park. Für unsere
                        Mitarbeitenden bieten wir die modernste Technik, kurze
                        Wege und Möglichkeiten, die Pausen relaxt zu verbringen,
                        z.B. in unserem Health Kitchen Bistro. Übrigens:
                        Erreicht werden kann das neue Kinder-UKE ganz einfach
                        mit dem Bus, dem StadtRad oder den Carsharing-Angeboten
                        erreichen.
                      </p>
                      <br />
                      <h3>
                        <b>Wir übernehmen deine Umzugskosten</b>
                      </h3>
                      <p>
                        Wir möchten dich bestmöglich beim Start in unserer
                        schönsten Stadt der Welt unterstützen. Sollte für deine
                        Tätigkeit im Kinder-UKE ein Umzug nach Hamburg nötig
                        werden, übernehmen wir für dich die entstandenen Kosten
                        bis zu einer Höhe von 1.000 Euro. Voraussetzung dafür
                        ist, dass ein Umzug von außerhalb des HVV-Großbereiches
                        notwendig wird. Sprich uns gerne beim
                        Vorstellungsgespräch auf eine finanzielle
                        Umzugsunterstützung an.
                      </p>
                    </div>
                    <div className="col-md-10 offset-md-1 col-12 text-start mt-4">
                      <h3>
                        <b>... was du noch erwarten kannst:</b>
                      </h3>
                      <ul>
                        <li>
                          Dienstplangestaltung
                          <ul>
                            <li>
                              Du arbeitest maximal sieben Tage hintereinander
                            </li>
                            <li>Du arbeitest maximal vier Nächte am Stück</li>
                            <li>
                              Wir berücksichtigen nach Möglichkeit deine Wünsche
                              bei der Dienstplangestaltung
                            </li>
                          </ul>
                        </li>
                        <li>
                          Teilnahme an Arbeitsgruppen zur Weiterentwicklung der
                          Pflege und/oder Expertenstandards
                        </li>
                        <li>
                          Qualifikation innerhalb des Mentorensystems
                          (Hygienementor:in, pädiatrische:r Schmerzmentor:in,
                          Mentor:in für neue
                          Mitarbeitende/Auszubildende/Praktikant:innen)
                        </li>
                        <li>
                          Qualifikation zur:m Ethikberater:in,
                          Praxisanleiter:in, Urotherapeut:in, Ambulanzmanager:in
                        </li>
                        <li>
                          Teilnahme an Kongressen und an fachspezifischen
                          Fortbildungen (Triagesystem in der Notaufnahme,
                          Transplantationen, Wundversorgung etc.)
                        </li>
                        <li>
                          Teilnahme an Fortbildungen unser UKE Akademie für
                          Bildung und Karriere
                        </li>
                        <li>
                          Freiwillige Mitarbeit bei unserem Kooperationspartner
                          „Kinderlotse e.V“
                        </li>
                        <li>
                          Möglichkeit der Teilnahme an der pädiatrischen
                          Anästhesie- und Intensivfachweiterbildung oder der
                          onkologischen Fachweiterbildung
                        </li>
                        <li>
                          Option zur Qualifikation im Bereich Pflegemanagement
                        </li>
                        <li>
                          Option auf einen Stipendiumplatz zur
                          Master-Qualifikation im Bereich Pflege an der
                          Hochschule für Angewandte Wissenschaft
                        </li>
                      </ul>
                    </div>
                  </div>
                </section>

                <section className="bg-blue divider-left mt-5">
                  <div className="row align-items-center">
                    <div className="col-md-10 offset-md-1 col-12 text-start">
                      <h2>
                        <b>
                          Unser Einarbeitungskonzept - auf dich zugeschnitten
                        </b>
                      </h2>
                      <p>
                        Bei uns startest du immer mit übergeordneten UKE-
                        Einführungstagen. Zwei Tage lang lernst du das UKE
                        berufsübergreifend kennen. Danach verbringst du drei
                        weitere gemeinsame Tage mit allen Pflegenden im UKE, die
                        im gleichen Monat mit dir anfangen. Hier erhältst du
                        breitgefächerte Informationen rund um die Uniklinik und
                        die Entwicklungsmöglichkeiten sowie Schulungen,
                        Pflichtfortbildungen rund um deinen neuen
                        Tätigkeitsbereich.
                        <br />
                        Danach fängt die fachspezifische Einarbeitung in deinem
                        neuen Bereich an. Feste Ansprechpartner: innen,
                        Schulungstage, regelmäßiges Feedback, inhaltliche
                        Einarbeitungskonzepte pro Bereich und langsame
                        Steigerung bei der selbstständigen Übernahme der
                        Patient:innenversorgung sind unsere Stärken.
                        <br />
                        Und falls du möchtest, haben deine Kolleg:innen auch
                        Tipps für Unternehmungen in Hamburg. Auch das gehört bei
                        uns zur Einarbeitung dazu.
                        <br />
                        Wir wissen, dass gerade die Anfangsphase für dich sehr
                        aufregend ist und du viele neue Eindrücke sammelst.
                        Damit die Erholung nicht zu kurz kommt, darfst du bei
                        uns auch während der Probezeit anteilig Urlaub nehmen.
                        <br />
                        Uns ist es wichtig, dass du einen guten Start bei uns
                        hast!
                      </p>
                    </div>
                  </div>
                </section>

                <section className="bg-blue divider-right mt-5">
                  <div className="row align-items-center">
                    <div className="col-md-10 offset-md-1 col-12 text-start">
                      <h2>
                        <b>Das Kinder-UKE</b>
                      </h2>
                      <p>
                        Als UKE bieten wir dir vielfältige Arbeitsumfelder der
                        Pädiatrie, in denen du dich bei uns einbringen und
                        verwirklichen kannst.
                      </p>
                      <p>
                        Das neue Kinder-UKE bietet Kindern bis 18 Jahre eine
                        Maximalversorgung. Die klinischen Schwerpunkte umfassen
                        Krebserkrankungen, schwere neurologische und seltene
                        angeborene Erkrankungen. Patientinnen und Patienten
                        berichten in diesem Film wie sich Spitzenmedizin und
                        Fürsorge im neuen Kinder-UKE vereinen.
                      </p>
                    </div>
                  </div>

                  <div className="row align-items-center">
                    <div className="col-md-10 offset-md-1 col-12">
                      <figure className="video-container video-container--static">
                        <iframe
                          src="https://player.vimeo.com/video/403210251"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          frameBorder="0"
                          webkitallowfullscreen="true"
                          mozallowfullscreen="true"
                          allowFullScreen
                          loading="lazy"
                        ></iframe>
                      </figure>
                    </div>
                  </div>
                </section>

                <section className="bg-blue divider-left">
                  <div className="row align-items-center">
                    <div className="col-md-10 offset-md-1 col-12">
                      <h2>
                        <b>Der Bau des neuen Kinder-UKE</b>
                      </h2>
                      <p>
                        Das neue Kinder-UKE ist fertig gebaut und wurde im
                        September 2017 eröffnet und bezogen.
                        <br />
                        Schau dir hier Bilder unseres Neubaus an:
                      </p>
                      <div id="superslide" className="carousel slide w-100">
                        <div className="carousel-inner">
                          <div className="carousel-item active">
                            <img
                              src="/img/kinder-und-jugendmedizin/Kinder_UKE_Baum_20150731_3.jpg"
                              alt="Bildansichten vom Kinder-UKE"
                              loading="lazy"
                            />
                            <p>
                              <small>Außenaufnahme des Eingangsbereichs</small>
                            </p>
                          </div>

                          <div className="carousel-item">
                            <img
                              src="/img/kinder-und-jugendmedizin/Kinder_UKE_Aussen_20170602_106FAV.jpg"
                              alt="Bildansichten vom Kinder-UKE"
                              loading="lazy"
                            />
                            <p>
                              <small>Die Fassade des neuen Kinder-UKE</small>
                            </p>
                          </div>

                          <div className="carousel-item">
                            <img
                              src="/img/kinder-und-jugendmedizin/Kinder_UKE_20170823_Innen-17.jpg"
                              alt="Bildansichten vom Kinder-UKE"
                              loading="lazy"
                            />
                            <p>
                              <small>Der Empfangsbereich des Kinder-UKE</small>
                            </p>
                          </div>
                          <div className="carousel-item">
                            <img
                              src="/img/kinder-und-jugendmedizin/Kinder_UKE_20170823_Innen-21.jpg"
                              alt="Bildansichten vom Kinder-UKE"
                              loading="lazy"
                            />
                            <p>
                              <small>
                                Der Eingangs- und Empfangsbereich im neuen
                                Kinder-UKE
                              </small>
                            </p>
                          </div>
                          <div className="carousel-item">
                            <img
                              src="/img/kinder-und-jugendmedizin/Kinder_UKE_20170815_1302.jpg"
                              alt="Bildansichten vom Kinder-UKE"
                              loading="lazy"
                            />
                            <p>
                              <small>
                                Beispiel eines neuen Spielzimmer mit
                                Bauelementen im Stil des Hamburger Hafens.
                              </small>
                            </p>
                          </div>
                          <div className="carousel-item">
                            <img
                              src="/img/kinder-und-jugendmedizin/Kinder_UKE_20170316_116FAV.jpg"
                              alt="Bildansichten vom Kinder-UKE"
                              loading="lazy"
                            />
                            <p>
                              <small>
                                Beispiel eines neuen Patientenzimmers
                              </small>
                            </p>
                          </div>
                          <div className="carousel-item">
                            <img
                              src="/img/kinder-und-jugendmedizin/Neubau_Kinder_UKE_20170215_12FAV.jpg"
                              alt="Bildansichten vom Kinder-UKE"
                              loading="lazy"
                            />
                            <p>
                              <small>
                                Kleiner Innenhof des neuen Kinder-UKE
                              </small>
                            </p>
                          </div>
                          <div className="carousel-item">
                            <img
                              src="/img/kinder-und-jugendmedizin/Kinder_UKE_Aussen_20170602_165.jpg"
                              alt="Bildansichten vom Kinder-UKE"
                              loading="lazy"
                            />
                            <p>
                              <small>
                                Eingang zur Hämatologisch-Onkologischen Ambulanz
                              </small>
                            </p>
                          </div>
                          <div className="carousel-item">
                            <img
                              src="/img/kinder-und-jugendmedizin/Neubau_Kinder_UKE_20170215_12FAV.jpg"
                              alt="Bildansichten vom Kinder-UKE"
                              loading="lazy"
                            />
                            <p>
                              <small>
                                Hainbuche und Glasfront im großen Innenhof
                              </small>
                            </p>
                          </div>
                        </div>

                        <div className="carousel-nav">
                          <button
                            className="carousel-control-prev"
                            data-bs-target="#superslide"
                            role="button"
                            data-bs-slide="prev"
                          >
                            <span
                              className="carousel-control-prev-icon"
                              aria-hidden="true"
                            ></span>
                            <span className="visually-hidden">Previous</span>
                          </button>
                          <button
                            className="carousel-control-next"
                            data-bs-target="#superslide"
                            role="button"
                            data-bs-slide="next"
                          >
                            <span
                              className="carousel-control-next-icon"
                              aria-hidden="true"
                            ></span>
                            <span className="visually-hidden">Next</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-center mt-3">
                    <div className="col-md-10 offset-md-1 col-12 mt-5">
                      <p>
                        Bereits im Juni 2014 begann der Bau unseres neuen
                        Kinder-UKE und startete zu dem Zeitpunkt mit dem Rückbau
                        alter Gebäude. Die Fläche des Neubaus wurde dann für den
                        eigentlichen Bau vorbereitet. Vor Baubeginn stand nur
                        noch die Hainbuche, die sich später im Innenhof des
                        neuen Kinder-UKE befindet.
                        <br />
                        <br />
                        Schauen Sie sich hier unseren Film zum Neubau des
                        Kinder-UKE an, den wir bereits in 2015 zum Baubeginn
                        erstellt haben und bei dem die Wünsche unserer jungen
                        Patientinnen und Patienten berücksichtigt wurden.
                      </p>
                    </div>
                  </div>
                  <div className="row align-items-center">
                    <div className="col-md-10 offset-md-1 col-12">
                      <figure className="video-container video-container--static">
                        <iframe
                          src="https://player.vimeo.com/video/403281795"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          frameBorder="0"
                          webkitallowfullscreen="true"
                          mozallowfullscreen="true"
                          allowFullScreen
                          loading="lazy"
                        ></iframe>
                      </figure>
                    </div>
                  </div>
                </section>

                <section className="bg-blue divider-left">
                  <div className="row align-items-center">
                    <div className="col-md-10 offset-md-1 col-12 text-start">
                      <h2>
                        <b>Das UKE als Arbeitgeber</b>
                      </h2>
                      <h3>
                        <b>
                          Was dir das UKE als Arbeigeber bietet, findest du{' '}
                          <a
                            href="https://www.uke.de/allgemein/karriere/das-bieten-wir-als-arbeitgeber/index.html"
                            title="Weiterleitung zu UKE.DE - Thematik: Das bieten wir dir als Arbeitgeber"
                          >
                            hier
                          </a>
                          .
                        </b>
                      </h3>
                    </div>
                  </div>
                </section>
              </>
            )}

            {editableData.key && editableData.key === 'herz-and-gefassmedizin' && (
              <>
                <section className="mt-5">
                  <div className="row align-items-center">
                    <div className="col-md-10 offset-md-1 col-12">
                      <h2>Du hast Fragen?...</h2>
                      <p>
                        ...zu unseren Stationen und Arbeitsbereichen? Du
                        möchtest dich bei uns bewerben? Du hast Interesse, uns
                        und dein neues Team bei einer Hospitation
                        kennenzulernen? Dann melde dich – wir freuen uns auf
                        dich! Gemeinsam finden wir den Bereich, der zu dir und
                        deinen Vorstellungen passt.
                      </p>
                    </div>
                  </div>

                  <figure>
                    <div className="row">
                      <div className="col-md-10 offset-md-1 mt-3">
                        <div className="row align-items-center">
                          <div className="col-md-6 order-1 ">
                            <img
                              src="/img/herz-und-gefaessmedizin/Portrait_Zentrumleitung_Barbara_Napp.jpg"
                              alt="Portrait: Frau Barbara Napp"
                              loading="lazy"
                            />
                          </div>

                          <div className="col-md-6 order-md-2 order-3">
                            <ul className="contact-channels">
                              <li className="phone">
                                <a href="tel:+4940741057430">
                                  040 7410 - 57430
                                </a>
                              </li>
                              <li className="email">
                                <a href="mailto:b.napp@uke.de">b.napp@uke.de</a>
                              </li>
                            </ul>
                          </div>

                          <div className="col-md-6 order-md-3 order-2">
                            <figcaption className="mb-4">
                              <b>Barbara Napp</b>
                              <br />
                              Pflegerische Zentrumsleiterin
                              <br />
                              Universitäres Herz- und Gefäßzentrum UKE Hamburg
                            </figcaption>
                          </div>
                        </div>
                      </div>
                    </div>
                  </figure>
                </section>
              </>
            )}

            <StaticReferenceModules referencesData={stationReferences} />
          </InlineForm>
        </Editor>
        <Footer />
      </main>
    </CMSWrapper>
  )
}

function StationShortList({ stations }) {
  return stations.map((station, index) => (
    <li key={index}>
      <Link to={`..${station.slug}`} title={`Zur ${station.header.title}`}>
        {station.header.title}
      </Link>
    </li>
  ))
}

function StationLongList({ stations }) {
  return stations.map((station, index) => (
    <section
      key={index}
      id={`station-${index}`}
      className="cluster-section bg-blue divider-left"
    >
      <div className="row align-items-center">
        <div className="col-md-5 offset-md-1 col-12 text-center">
          {station.header.handlettering && (
            <StaticImage image={station.header.handlettering} />
          )}
        </div>

        <div className="col-md-5 col-12 text-center">
          {station.header.image && <StaticImage image={station.header.image} />}
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-md-10 col-12 offset-md-1 text-center mt-4">
          <h2 className="mt-4">
            <strong>{station.header.title}</strong>
          </h2>
          <h4 className="mt-4">{station.header.introText}</h4>
          <div className="row mt-4 mb-4 text-center justify-content-center">
            <div>
              <Button color="yellow" href={`..${station.slug}`}>
                Zur {station.header.title}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  ))
}

export const query = graphql`
  query ClusterReferencesQuery($id: String, $referenceType: [String]) {
    cluster(_id: { eq: $id }) {
      _id
      handlettering
      key
      title
      path
      slug
      landingpageTitle
      landigpageQuote
      showShortList
      showLongList
      landingpageShowInExplorer
    }
    allReference(filter: { types: { in: $referenceType } }) {
      nodes {
        _id
        content {
          sections {
            _template
            bgColor
            grids {
              _template
              columns {
                _template
                altText
                description
                descriptionAlignment
                showFrame
                filename
                listStyle
                text
                textAlign
                url
                images {
                  _template
                  altText
                  description
                  filename
                  showFrame
                }
              }
              itemAlignment
              marginType
            }
          }
        }
        mediaDir
        priority
        slug
        title
      }
    }
  }
`
